.trusted-partners {
  margin: 6rem 0 !important;
}
.trusted-by-partners-wrapper h2 {
  font-size: 2.8rem;
  text-align: center;
}
.partner-logo-images {
  flex-wrap: wrap;
  column-gap: 12rem;
  justify-content: center;
  margin-top: 3rem;
  align-items: center;
  row-gap: 4rem;
  transition: 0.8s;
  transform: scale(0);
}
.partner-logo-images img {
  width: 90px;
}

.section-animation .partner-logo-images {
  transform: scale(1);
}
@media (max-width: 768px) {
  .trusted-by-partners-wrapper h2 {
    text-align: left;
  }
  .partner-logo-images {
    display: flex;
    gap: 40px;
    margin-top: 4rem;
  }
  .partner-logo-images img {
    width: 14rem;
  }
}
@media (max-width: 500px) {
  .partner-logo-images {
    justify-content: flex-start;
  }
}
