.roi-section {
  background: #39075b;
  padding: 6rem 0;
}
.roi-section .section-heading {
  border-bottom: 2px solid #fff;
}
.roi-section .heading-description h2 {
  width: 100%;
  color: #fff;
}
.roi-content-wrapper {
  display: flex;
  align-items: center;
  gap: 4rem;
  margin: 6rem 0 3rem;
}
.roi-card {
  width: 33%;
  position: relative;
}
.roi-card span {
  font: 400 5rem "Krona One";
  color: #fff;
}
.roi-card h3 {
  font-size: 2.4rem;
  color: #fff;
  margin: 1.5rem 0 2.5rem;
}
.roi-card img {
  transform: scale(0);
  transition: all 1s ease;
  transform-origin: left;
  width: 100%;
}
.section-animation .roi-card img {
  transform: scale(1);
}
@media (max-width: 768px) {
  .roi-content-wrapper {
    flex-direction: column;
  }
  .roi-card {
    width: 100%;
  }
  .divider {
    display: none;
  }
}
@media (max-width: 576px) {
  .roi-card::after {
    content: none !important;
  }
  .roi-content-wrapper {
    flex-direction: column;
  }
}
@media (max-width: 400px) {
  .heading-tag p {
    font-size: 2.2rem !important;
  }
  .section-heading h2 {
    font-size: 3.4rem;
  }
  .section-heading p {
    font-size: 13px;
  }
}
h3 br {
  display: none;
}
@media (min-width: 769px) and (max-width: 1640px) {
  h3 br {
    display: inline;
  }
}
