.solution-section {
  background: #fdfbf7;
  padding: 6rem 0;
}

.section-heading {
  padding: 0 2rem;
  border-bottom: 2px solid #39075b;
}
.heading-tag span {
  font-size: 1.4rem;
  font-weight: 700;
  color: #111827;
}
.heading-description {
  display: flex;
  justify-content: space-between;
}
.heading-description h2 {
  width: 44%;
}
.heading-description p {
  width: 35%;
}

/* First Tab Switch */
.first-tab-switch-wrapper {
  margin-top: 6rem;
  padding: 0 2rem;
}
.first-tab-switch-content {
  display: none;
  animation: fadeEffect 1s;
}
.first-tab-switch-content.active {
  display: block;
}
@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.tab-links-container {
  display: flex;
  justify-content: space-between;
}
.tab-links-container div {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-top: 1px solid #6b7589;
  padding: 3rem 2rem 3rem 0;
  cursor: pointer !important;
}
/* .tab-links-container div:hover {
  border-radius: 0 0 12px 12px;
  background-color: #ffffff;
  transform: scale(1);
  box-shadow: 5px 5px 0 0 #000;
} */
.tab-links-container .active {
  border-top: 4px solid #39075b;
  padding-top: calc(3rem - 3px);
}
.tab-switch-inner {
  position: relative;
  padding: 7rem 3rem 0 0;
  background: #39075b;
  border-radius: 22px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8rem;
}
.tab-switch-inner::after {
  content: "";
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  border-radius: 22px;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: -15px;
  right: -15px;
}
.tab-switch-image {
  width: 55%;
  position: relative;
  z-index: 1;
}
.tab-switch-image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 17%;
  height: 100%;
  background: linear-gradient(91deg, #39075b8a, transparent);
  border-bottom-left-radius: 22px;
}
.tab-switch-text {
  width: 40%;
  z-index: 1;
}
.tab-switch-text h3 {
  font-size: 4rem;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 2rem;
}
.tab-switch-text p {
  font-size: 1.8rem;
  color: #ffffff;
}
@media (max-width: 1640px) {
  .heading-description h2 {
    width: 49%;
  }
}
.first-tab-switch-wrapper img {
  border-radius: 0 0 0 22px;
}
@media (max-width: 1440px) {
  .heading-description h2 {
    width: 62%;
  }
  /* .heading-description p {
    width: 28%;
  } */
}
@media (max-width: 1024px) {
  .heading-description h2 {
    width: 60%;
  }

  .heading-description p {
    width: 35%;
  }
}
@media (max-width: 768px) {
  .heading-description {
    flex-direction: column;
    gap: 2rem;
  }
  .section-heading {
    padding-bottom: 2rem;
  }
  .heading-description h2,
  .heading-description p {
    width: 100%;
    margin: 0;
  }
  .tab-switch-inner {
    flex-direction: column;
    gap: 3rem;
    padding: 5rem 3rem;
  }
  .tab-switch-image,
  .tab-switch-text {
    width: 100%;
  }
  .tab-links-container {
    flex-direction: column;
  }
  .tab-links-container div {
    width: 75%;
    padding-bottom: 0;
    margin-bottom: 4rem;
  }
}
@media (max-width: 576px) {
  .tab-switch-text h3 {
    font-size: 3.5rem;
  }
}
@media (max-width: 456px) {
  .tab-links-container div {
    width: 100%;
  }
}
