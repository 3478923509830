.benefits-section .section-heading {
  border-bottom: 2px solid #000000;
}
.benefits-cards-wrapper {
  margin-top: 8rem;
  row-gap: 3rem;
}
.benefits-card-inner {
  padding: 3rem;
  border: 1px solid #000000;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
  height: 100%;
  transition: all 0.3s ease;
  transform: translate(0);
  box-shadow: none;
}
.benefits-card-inner img {
  width: 320px;
}
.benefits-card-inner::after {
  content: "";
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: -12px;
  right: -12px;
  z-index: -1;
}
.benefits-card-inner:hover {
  transform: translate(-3px, -5px);
  box-shadow: 12px 12px 0px 0px #000000;
}
.benefits-first-card {
  background: #768fe6;
  padding: 3rem 0;
}

.benefits-first-card-text {
  padding: 0 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.benefits-second-card {
  background: #feb64e;
}
.benefits-third-card {
  background: #ffe6bc;
}
.benefits-fourth-card {
  background: #f39460;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10rem 3rem;
}
.benefits-fourth-card-text {
  width: 39%;
  width: 39%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.benefits-fourth-card-image {
  width: 51%;
}
.benefits-fourth-card-image img {
  width: 100%;
}
.benefits-fifth-card {
  background: #00c9b2;
}
@media (max-width: 1024px) {
  .benefits-fourth-card {
    flex-direction: column;
    padding: 3rem;
  }
  .benefits-fourth-card div {
    width: 100%;
  }
  .benefits-cards-wrapper {
    row-gap: 5rem;
  }
}
