.second-banner-section-wrapper {
  padding-top: 6rem;
  background: #003f30;
  border-radius: 22px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.second-banner-section-heading {
  width: 46%;
  padding: 0 0 8rem 8rem;
}
.second-banner-section-heading h2 {
  font-size: 4.7rem;
  color: #fff;
}
.second-banner-section-heading p {
  font-size: 1.8rem;
  color: #fff;
}
.second-banner-button-wrapper {
  margin-top: 5rem;
  transition: 0.6s;
}
.second-banner-button {
  background: #ffa33e;
  border: 1px solid #1d1d1d;
  border-radius: 12px;
  display: inline-block;
  min-height: 48px;
  padding: 2rem 5.5rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
  z-index: 10;
  cursor: pointer;
  transform: translate(-3px, -5px);
  box-shadow: 5px 5px 0px 0px #000000;
}
.second-banner-button:hover {
  transform: translate(0);
  box-shadow: none;
}
.second-banner-button p {
  font-size: 1.8rem;
  font-weight: 700;
  margin: 0;
  color: #000000;
  width: 100% !important;
}
@media (max-width: 1440px) {
  .second-banner-section-heading {
    width: 59%;
    padding: 0 0 4rem 4rem;
  }
  .second-banner-image {
    width: 34%;
  }
}
@media (max-width: 1024px) {
  .second-banner-section-wrapper {
    padding-top: 4rem;
  }
}
@media (max-width: 768px) {
  .second-banner-section-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .second-banner-section-heading {
    padding-right: 4rem;
  }
  .second-banner-section-wrapper div {
    width: 100%;
  }
  .second-banner-image {
    width: 60% !important;
    margin-left: auto;
  }
}
@media (max-width: 400px) {
  .second-banner-section-heading h2 {
    font-size: 3.6rem;
  }
  .second-banner-section-heading p {
    font-size: 1.6rem;
  }
  .second-banner-image {
    width: 60% !important;
  }
}
