.features-section h2 {
  color: #194221;
}
.features-section .section-heading {
  border-bottom: 2px solid #000000;
}
.features-cards-wrapper {
  margin-top: 7rem;
  row-gap: 2.5rem;
}
.feature-card-inner {
  display: flex;
  gap: 2rem;
  padding: 2rem;
  background: #f3f3f3;
  border-radius: 12px;
  border: 1px solid #000000;
  transition: all 0.3s ease;
  transform: translate(-3px, -5px);
  box-shadow: 8px 8px 0px 0px #000000;
  height: 100%;
}
.feature-card-inner:hover {
  transform: translate(0);
  box-shadow: none;
}
.feature-icon {
  flex-shrink: 0;
}
.feature-text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.feature-text h3 {
  font-size: 2rem;
}
.feature-text p {
  color: #111827;
}
@media (max-width: 768px) {
  .features-cards-wrapper {
    row-gap: 4.5rem;
  }
}
