/* Banner  */
.banner-section {
  background: url(../../assets/BannerSection/BannerBG.svg) no-repeat center
    center / cover;
  background-position-y: -15rem;
}
.banner-content-wrapper {
  padding-top: 20rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.left-side {
  width: 53%;
  display: flex;
  flex-direction: column;
}
.right-side {
  width: 41%;
  transform: scale(0);
  transition: 0.8s ease;
}
.right-side img {
  width: 100%;
}
.banner-section h1 {
  transform: translateY(50px);
  opacity: 0;
  transition: 0.6s;
}
.banner-content-wrapper p {
  color: #000000;
  font-size: 2rem;
  width: 90%;
}
.left-side > p {
  transform: translateY(50px);
  transition: 0.6s;
  opacity: 0;
}
.banner-button-wrapper {
  margin-top: 5rem;
  transform: translateY(50px);
  transition: 0.6s;
  opacity: 0;
}
.banner-button {
  background: #ffa33e;
  border: 1px solid #1d1d1d;
  border-radius: 12px;
  display: inline-block;
  min-height: 48px;
  padding: 2rem 5.5rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
  z-index: 10;
  cursor: pointer;
  transform: translate(-3px, -5px);
  box-shadow: 5px 5px 0px 0px #000000;
}
.banner-button:hover {
  transform: translate(0);
  box-shadow: none;
}
.banner-button p {
  font-size: 1.8rem;
  font-weight: 700;
  margin: 0;
  width: 100% !important;
}
.elixir-logo{
  cursor: pointer;
}

.banner-cards-wrapper {
  justify-content: space-between;
  margin: 12rem 0;
  display: flex;
  text-align: center;
}
.banner-card {
  width: 30%;
  padding: 4rem 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  border: 1px solid #000000;
  background: #ffffff;
  border-radius: 22px;
  align-items: center;
  gap: 2.5rem;
  transition: all 0.4s ease-in-out;
}
.banner-card img {
  width: 5rem;
}
.banner-card h2 {
  font-size: 2.4rem;
  color: #111827;
  margin: 0;
}
.banner-card:hover {
  transform: translate(-3px, -5px);
  box-shadow: 8px 8px 0px 0px #000000;
  cursor: default;
}
/* Animate */
.banner-section.animate h1 {
  transform: translateY(0px);
  opacity: 1;
}
.animate .left-side > p {
  transform: translateY(0px);
  opacity: 1;
  transition-delay: 0.3s;
}
.banner-section.animate .banner-button-wrapper {
  transform: translateY(0px);
  opacity: 1;
  transition-delay: 0.6s;
}
.banner-section.animate .right-side {
  transform: scale(1);
}

@media (max-width: 1640px) {
  .left-side {
    width: 60%;
  }
  .right-side {
    width: 37%;
  }
}
@media (min-width: 1201px) and (max-width: 1440px) {
  .left-side {
    width: 61%;
  }
  .banner-section h1 {
    font-size: 4.3rem;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .left-side {
    width: 63%;
  }
  .banner-section h1 {
    font-size: 3.8rem;
  }
  .banner-content-wrapper p {
    font-size: 1.8rem;
  }
}
@media (max-width: 1024px) {
  .banner-content-wrapper {
    width: 100%;
    flex-direction: column;
    padding-top: 15rem;
    row-gap: 8rem;
  }
  .left-side {
    width: 100%;
  }
  .right-side {
    width: 100%;
    text-align: center;
  }
  .right-side img {
    width: 50%;
  }
  .banner-cards-wrapper {
    margin: 12rem 0;
  }
}
@media (max-width: 768px) {
  .banner-section {
    background-position-y: -40rem;
  }
  .right-side img {
    width: 90%;
  }
  .banner-cards-wrapper {
    flex-direction: column;
    gap: 2.5rem;
  }
  .banner-cards-wrapper div {
    width: 100%;
  }
}
@media (max-width: 450px) {
  .banner-content-wrapper p {
    font-size: 13px;
  }
}
