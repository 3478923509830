.error-image {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-image img {
  width: 50%;
  height: 50%;
}
